export const about = {
    title: "<span class='has-text-success'>About me</span>",
    content:
        "<span class='has-text-info'>My name is Bruno Domingos and I am a proactive and focused developer with eight years’ experience in a variety of development positions with a personal taste for frontend development. Organised, curious, passionate and a keen eye for detail. A person who loves what he does.</span>",
    core_skills: [
        "<span class='has-text-warning'>Skilled frontend developer</span>",
        "<span class='has-text-warning'>Passionate for UI&UX</span>",
        "<span class='has-text-warning'>Curious and aware with current and new development tools and practices</span>",
        "<span class='has-text-warning'>Experience in hybrid mobile applications</span>",
    ],
};
