import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import { home as data } from 'data/home';

export default function HomePage({ tags, projects, route }) {
    return (
        <Layout route={route} section="" seo={{ title: 'Home' }}>
            <pre dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, 2) }} />
        </Layout>
    );
}

HomePage.propTypes = {
    tags: PropTypes.array,
    projects: PropTypes.array,
    route: PropTypes.string,
};
