import { about } from './about';
import { formatedTags, getFormatedProjects } from 'utils/functions';

const latestWork = getFormatedProjects()
    .slice(0, 3)
    .map(p => {
        return {
            title: p.title,
            subtitle: p.subtitle,
            url: p.url,
            tags: p.tags,
        };
    });

export const home = {
    title: "<span class='has-text-success'>Bruno Domingos</span>",
    subtitle: "<span class='has-text-info'>Doing stuff for the web since 2010.</span>",
    url: "<a class='has-text-link' href='/'>/home</a>",
    about: {
        ...about,
        url: "<a class='has-text-link' href='/about'>/about</a>",
    },
    latest_work: latestWork,
    tags: formatedTags(),
};
